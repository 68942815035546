/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Customer Detail
 *   Version : 1.0.0
*/


// packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// Custom components
import { Breadcrumb, EmptyScreen } from "../../../Components/Others";
import { Badge } from "../../../Components/Badge";
import { DataTableContainer } from "../../../Components/DataTable";
import { OrderProgressModal } from "../../../Components/Modal";

// Custom styles
import Colors from '../../../Styles/color.module.scss';

// Service
import APIService from "../../../Services/api-service";
import Config from "../../../Helper/config";


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const OrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showOrderDetail, setShowOrderDetail] = useState(null);

    const [orderDetail, setOrderDetail] = useState({});
    const [apiLoader, setApiLoader] = useState(true);



    useEffect(() => {
        if (showOrderDetail !== null) {
            const modal = new Modal(document.getElementById("order-progress"), {});
            modal.show();
        }
    }, [showOrderDetail]);

    useEffect(() => {
        if (location.state !== null) {
            _getOrderDetail(location.state.id);
        }
    }, [location]);

    function _handleNavigate() {
        navigate("/orders", {
            state: {
                page: location.state.page,
                selectedPaymentStatus: location.state.selectedPaymentStatus,
                selectedOrderStatus: location.state.selectedOrderStatus,
                selectedOrderType: location.state.selectedOrderType,
                selectedMilestone: location.state.selectedMilestone,
                fromDate: location.state.fromDate,
                endDate: location.state.endDate,
                amountFrom: location.state.amountFrom,
                amountTo: location.state.amountTo,
                search: location.state.search
            }
        })
    }

    function _handleOrderDetailModal(row) {
        setShowOrderDetail(row);
    }

    // API - order detail
    const _getOrderDetail = (id) => {
        const url = "/orders/detail";
        const request = {
            order_id: id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data);
            }
            setApiLoader(false);
        })
    }

    const ORDER_COLUMNS = [
        {
            name: "Fund",
            cell: row => <div className="d-block">
               {orderDetail.summary.is_external === true &&<div class="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                    <img src={require("../../../Assets/Images/Icons/external.svg").default}
                        alt="external"
                        width={18} />
                    External
                </div>}
                <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                    <img src={row.image}
                        alt={row.mutual_fund}
                        width={38}
                        height={38}
                        draggable={false}
                        className="border-radius-8px border-1px border-color-pale-gray p-1" />
                    <span className="ps-2">{row.mutual_fund}</span>
                </div>
            </div>,
            width: "300px"
        },

        {
            name: "Switch in",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? false : orderDetail.summary && orderDetail.summary.type === "STP" ? false : true,
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                {row.switch_in.name}
            </div>,
            width: "200px"
        },

        {
            name: "Switch out",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? false : orderDetail.summary && orderDetail.summary.type === "STP" ? false : true,
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                {row.switch_out.name}
            </div>,
            width: "200px"
        },
        {
            name: "Switch in NAV",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? false : orderDetail.summary && orderDetail.summary.type === "STP" ? false : true,
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                {row.switch_in.nav ? row.switch_in.nav : "-"}
            </div>,
            width: "200px"
        },

        {
            name: "Switch out NAV",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? false : orderDetail.summary && orderDetail.summary.type === "STP" ? false : true,
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                {row.switch_out.nav ? row.switch_out.nav : "-"}
            </div>,
            width: "200px"
        },
        {
            name: "Switch in Units",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? false : orderDetail.summary && orderDetail.summary.type === "STP" ? false : true,
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                {row.switch_in.units ? row.switch_in.units : "-"}
            </div>,
            width: "200px"
        },

        {
            name: "Switch out Units",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? false : orderDetail.summary && orderDetail.summary.type === "STP" ? false : true,
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>
                {row.switch_out.units ? row.switch_out.units : "-"}
            </div>,
            width: "200px"
        },
        {
            name: "Amount",
            cell: row => row.amount,
            width: "120px"
        },

        {
            name: "Units",
            omit: orderDetail.summary && orderDetail.summary.type === "Switch" ? true : orderDetail.summary && orderDetail.summary.type === "STP" ? true : false,
            width: "100px",
            cell: row => row.units ? row.units : "-"
        },
        {
            name: "Allocated NAV",
            omit: orderDetail.summary && orderDetail.summary.type !== "Switch" ? false : orderDetail.summary && orderDetail.summary.type !== "STP" ? false : true,
            cell: row => row.allocated_nav ? row.allocated_nav : "-",
            width: "130px",
        },
        {
            name: "Allocated date",
            cell: row => row.allocated_date ? row.allocated_date : "-",
            width: "130px",
        },

        {
            name: "Last updated date",
            width: "150px",
            cell: row => row.last_updated_date
        },

        {
            name: "Status",
            width: "140px",
            cell: row => <Badge status={row.status}
                color={row.status === "Success" ? Config.BADGE_COLORS.green :
                    row.status === "Failed" ? Config.BADGE_COLORS.red :
                        row.status === "Pending" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.blue} />
        },

    ]
    return (
        <Fragment>
            {
                apiLoader === true ?
                    <div className="h-80vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    Object.keys(orderDetail).length > 0 ?
                        <Fragment>
                            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                                <div className="padding-110px-bottom">
                                    <Breadcrumb handleNavigate={_handleNavigate}
                                        mainTitle="Order summary"
                                        subTitle={orderDetail.summary.order_no} />
                                </div>
                            </div>
                            <div className="e-body-wrapper">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="bg-white border-radius-16px py-4 padding-32px-lr">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-lg-2 col-md-2 col-2 pe-0">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex justify-content-center color-rebecca-purple e-montserrat-semi-bold e-font-14 e-line-height-18 e-link cursor-pointer text-decoration-hover-underline"
                                                            onClick={() => navigate("/customer/detail", {
                                                                state: {
                                                                    id: orderDetail.summary.customer_id,
                                                                    tab_index: 1
                                                                }
                                                            })}>
                                                            {orderDetail.summary.customer_name ? orderDetail.summary.customer_name : "-"} ({orderDetail.summary.mi_customer_id ? orderDetail.summary.mi_customer_id : "-"})
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-10 col-md-10 col-10 ps-0">
                                                    <div className="e-bg-pale-lilac p-3 d-flex flex-wrap row-gap-16px">
                                                        <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-24 mb-2">
                                                                Order no.
                                                            </h6>
                                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                                {orderDetail.summary.order_no}
                                                            </p>
                                                        </div>
                                                        <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-24 mb-2">
                                                                Created on
                                                            </h6>
                                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                                {orderDetail.summary.created_on}
                                                            </p>
                                                        </div>
                                                        <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                                Milestone
                                                            </h6>
                                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                                {orderDetail.summary.milestone ? orderDetail.summary.milestone : "-"}
                                                            </p>
                                                        </div>
                                                        <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                                Amount
                                                            </h6>
                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-dark-charcoal  pt-xl-0 pt-0">
                                                                {orderDetail.summary.total_amount}
                                                            </p>
                                                        </div>
                                                        <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                                Order Type
                                                            </h6>
                                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                                {orderDetail.summary.type}
                                                            </p>
                                                        </div>
                                                        <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                                Order status
                                                            </h6>
                                                            <Badge status={orderDetail.summary.order_status}
                                                                color={orderDetail.summary.order_status === "Successful" ? Config.BADGE_COLORS.green :
                                                                    orderDetail.summary.order_status === "Failed" ? Config.BADGE_COLORS.red :
                                                                        Config.BADGE_COLORS.orange} />
                                                        </div>

                                                        {orderDetail.summary.type !== "SWP" && orderDetail.summary.type !== "Withdraw" && orderDetail.summary.type !== "Switch" && <div className="padding-64px-right">
                                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                                Payment status
                                                            </h6>
                                                            <Badge status={orderDetail.summary.payment_status}
                                                                color={orderDetail.summary.payment_status === "Success" ? Config.BADGE_COLORS.green :
                                                                    orderDetail.summary.payment_status === "Failed" ? Config.BADGE_COLORS.red :
                                                                        Config.BADGE_COLORS.orange} />
                                                        </div>}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="position-relative bg-white border-radius-24px py-4 mt-4">
                                            <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                                                Order details
                                            </h4>
                                            <DataTableContainer data={orderDetail.data}
                                                columns={ORDER_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                rowClick={(row) => _handleOrderDetailModal(row)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        :
                        <EmptyScreen className="my-5"
                            title="No order detail found"
                            image={require("../../../Assets/Images/loader/empty-funds.png")}
                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
            }
            {
                showOrderDetail !== null && <OrderProgressModal
                    data={showOrderDetail}
                    type={orderDetail.summary.type}
                    closeModal={() => _handleOrderDetailModal(null)} />
            }
        </Fragment>
    )
}

export default OrderDetail