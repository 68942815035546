
/*
 *   File : download.js
 *   Author : https://evoqins.com
 *   Description : Download button
 *   Integrations : NA
 *   Version : 1.0.0
*/


// Packages
import { memo } from 'react';

// Custom Components
import { Icon } from '../Icon';

// Custom styles
import styles from '../../Styles/Components/download.module.scss';

const DownloadButton = (props) => {

    return (
        <button className={`${props.disabled ? styles.e_disabled_btn : ''} ${props.loader ? styles.e_disabled_btn : styles.e_download_btn} ${props.className} e-font-14 e-line-height-24 border-radius-6px border-none border-radius-8px justify-content-center align-items-center gap-8px w-max-content`}
            id={props.id}
            name={props.name}
            data-bs-dismiss={props.cancel}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            type={props.type}
            tabIndex="-1"
            onClick={props.onPress}>

            {props.label ? props.label : "Download MIS"}
            {
                props.disabled ? <Icon icon="download" size="24px"
                    className="ms-2" />
                    :
                    <Icon icon="download" size="24px"
                        className={props.loader ? "bounce ms-2" : "ms-2"}
                        color="#fff" />}
        </button>
    )
}
DownloadButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    type: '',
    id: null,
    name: "",
}
export default memo(DownloadButton);

