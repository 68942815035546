
/*
 *   File : order-progress.js
 *   Author URI : https://evoqins.com
 *   Description : Order detail modal 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, memo, useEffect } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";
import { _getTransactionStatus } from "../../Helper/helper";


const OrderProgressModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("order-progress");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_progress_modal}`}
                id="order-progress"
                tabIndex="-1"
                aria-labelledby="order-progress"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Order timeline
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className="margin-30px-top">
                            <div className="row">
                                <div className="col-12">
                                    <div className="border-bottom border-color-mint-gray padding-10px-bottom">
                                        <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                            Transaction ID
                                        </h6>
                                        <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                            {props.data.transaction_id ? props.data.transaction_id : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {(props.type === "Switch" || props.type === "STP") && <div className="row py-3">
                                <div className="col-6">
                                    <div className="border-right-1px border-color-mint-gray">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Switch out
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.switch_out.name}
                                                </p>
                                            </div>
                                            <div className="col-4 pt-3">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Units
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.switch_out.units}
                                                </p>
                                            </div>
                                            <div className="col-4 pt-3">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    NAV
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.switch_out.nav ? props.data.switch_out.nav : "-"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                Switch in
                                            </h6>
                                            <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                {props.data.switch_in.name}
                                            </p>
                                        </div>
                                        <div className="col-4 pt-3">
                                            <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                Units
                                            </h6>
                                            <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                {props.data.switch_in.units}
                                            </p>
                                        </div>
                                        <div className="col-4 pt-3">
                                            <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                NAV
                                            </h6>
                                            <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                {props.data.switch_in.nav}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className="row gx-3 pt-3">
                                {
                                    props.data.timeline.map((item, index) => {
                                        let step_data = _getTransactionStatus(item.status, index);

                                        return (
                                            <div className="col">
                                                <div className={`e-progress-image d-flex align-items-center justify-content-start position-relative`} >
                                                    <img src={step_data.icon} alt={"progress"}
                                                        draggable={false}
                                                        className="bg-white z-index-4" />
                                                    {
                                                        index !== props.data.timeline.length - 1 && <div
                                                            className="e-progress-line"
                                                            style={{
                                                                position: 'absolute',
                                                                width: '100%',
                                                                height: '1px',
                                                                left: '1.25rem',
                                                                zIndex: 1,
                                                                background: step_data.bg_color
                                                            }}
                                                        />
                                                    }
                                                </div>
                                                <h6 className={`mb-0 e-font-14 e-line-height-20 e-montserrat-bold pt-2 ${step_data.color}   `}>
                                                    {item.label}
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {item.value ? item.value : "-"}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* {
                                props.type === "Withdraw" || props.type === "SWP" ?
                                    <div className="row pt-4">
                                        <div className="col-4">
                                            <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                Order Initiated
                                            </h6>
                                            <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                {props.data.order_initiated ? props.data.order_initiated : "-"}
                                            </p>
                                        </div>

                                        <div className="col-4 ">
                                            <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                Order Captured
                                            </h6>
                                            <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                {props.data.order_captured ? props.data.order_captured : "-"}
                                            </p>
                                        </div>
                                        <div className="col-4 ">
                                            <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                Amount Sent To Bank
                                            </h6>
                                            <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                {props.data.payment_bank_date ? props.data.payment_bank_date : "-"}
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    (props.type === "Switch" || props.type === "STP") ?
                                        <div className="row pt-3">
                                            <div className="col-4">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Order Initiated
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.order_initiated ? props.data.order_initiated : "-"}
                                                </p>
                                            </div>

                                            <div className="col-4 ">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Order Captured
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.order_captured ? props.data.order_captured : "-"}
                                                </p>
                                            </div>
                                            <div className="col-4 ">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    {props.type === "Switch" ? "Order Switched" : "Units Allocated"}
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.units_allocated ? props.data.units_allocated : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        <div className="row pt-3">
                                            <div className="col-2 pe-0">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Order Initiated
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.order_initiated ? props.data.order_initiated : "-"}
                                                </p>
                                            </div>
                                            <div className="col-5 ">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Payment received from {props.data.payment_bank ? props.data.payment_bank : ""}
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.payment_bank_date ? props.data.payment_bank_date : "-"}
                                                </p>
                                            </div>
                                            <div className="col-3 ps-0">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Order Captured
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.order_captured ? props.data.order_captured : "-"}
                                                </p>
                                            </div>
                                            <div className="col-2 px-0">
                                                <h6 className="mb-0 e-font-14 e-line-height-20 e-montserrat-bold color-dark-gray">
                                                    Units allocated
                                                </h6>
                                                <p className="mb-0 e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                                    {props.data.units_allocated ? props.data.units_allocated : "-"}
                                                </p>
                                            </div>
                                        </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default memo(OrderProgressModal)