/*
 *   File : summary.js
 *   Author : https://evoqins.com
 *   Description : Dashboard Summary Card component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

// Packages
import { Fragment, memo } from "react"
import { Tooltip } from "react-tooltip";

const SummaryCard = (props) => {
    return (
        <Fragment>
            <div className={`bg-white border-radius-24px padding-32px-tb px-3 h-100 d-flex flex-column ${props.navigation === true ? "e-summary-card" : ""}`} onClick={() => props.navigation === true && props.navigateTo()}>
                <div className="d-flex align-items-center gap-8px mb-4">
                    <img src={props.icon}
                        alt={props.title}
                        width={32}
                        height={32}
                        draggable={false} />
                    <h6 className="color-dark-charcoal e-font-14 e-line-height-24 e-montserrat-medium mb-0">
                        {props.title}
                    </h6>
                </div>
                <span className=" color-dark-charcoal e-font-20 e-line-height-24 e-montserrat-semi-bold mt-auto w-max-content"
                    data-tooltip-id={props.title}>
                    {props.countText}
                </span>
                {
                    props.count &&
                    <Tooltip id={props.title}
                        className='e-summary-hover'>
                        <span className='color-white e-montserrat-regular e-font-12 e-line-height-18'>₹ {parseFloat(props.count).toLocaleString('en-IN')}</span>
                    </Tooltip>
                }
            </div>
        </Fragment>
    )
}

export default memo(SummaryCard);